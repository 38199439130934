























import { Component, Vue } from "vue-property-decorator";
import HotelSelector from "@/components/HotelSelector.vue";

@Component({
  components: {
    HotelSelector
  }
})
export default class PortMapping extends Vue {
  selectedHotel = { id: null, name: "", dnsName: "" };
  get invalidHotel() {
    return this.selectedHotel === null || this.selectedHotel.dnsName === "";
  }
  submit() {
    if (!this.invalidHotel) {
      this.$router.push({
        name: "PortMappingDocument",
        params: { siteDns: this.selectedHotel.dnsName }
      });
    }
  }
}
